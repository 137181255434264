@media print {
	.container-fluid {
		overflow: visible !important;
	}

	#main-content {
		overflow: visible !important;
	}
}

.inner-border-table td,
.inner-border-table th {
	border: none;
	border-right: 1px solid #707070;
	white-space: nowrap;
}

.inner-border-table__link {
	background-image: url("../svg/arrow-green-right.svg");
}

.inner-border-table tr td:first-child,
.inner-border-table tr th:first-child {
	width: 225px;
	min-width: 225px;
	white-space: normal;
	font-size: 16px;
	line-height: 19px;
}

thead+tbody tr:nth-child(odd) {
	background-color: rgba(133, 177, 132, .1);
}

th,
td {
	/* padding: 15px 15px 15px 15px;
	font-size: 14px;
	line-height: 17px; */
}

thead th {
	color: #285626;
	font-size: 18px;
	line-height: 21px;
	padding-top: 0;
	padding-bottom: 10px;
}

.table-wrapper {
	overflow-x: auto;
}

.table-wrapper.desktop {
	display: block;
}

@media (max-width: 992px) {
	.table-wrapper.desktop {
		display: none;
	}
}

.table-wrapper.mobile {
	display: none;
}

@media (max-width: 992px) {
	.table-wrapper.mobile {
		display: block;
	}
}

.btn-table {
	width: 184px;
	margin-top: 23px;
}

@media (max-width: 992px) {
	.btn-table {
		width: 127px;
		margin-top: 20px;
		padding: 6px 11px;
		margin-bottom: 30px;
	}
}

.margin-D-90-60-M-68-35 {
	margin-top: 30px;
	margin-bottom: 30px;
}

@media (max-width: 992px) {
	.margin-D-90-60-M-68-35 {
		margin-top: 0;
		margin-bottom: 20px;
	}
}

.table-accordion {
	margin-top: 30px;
	margin-bottom: 16px;
}

@media (max-width: 992px) {
	.table-accordion {
		margin-bottom: 0;
	}
}


.table-accordion__item {
	border-top: 1px solid #437945;
}

.table-accordion__item:last-child {
	border-bottom: 1px solid #437945;
}

.table-accordion__item .head {
	padding: 23px 32px 23px 0;
	background: url("../svg/arrow-green-down.svg") no-repeat center right;
}

.table-accordion__item .head.active {
	background: url("../svg/arrow-green-up.svg") no-repeat center right;
}

.table-accordion__item .head.active+.items {
	display: block;
}

.table-accordion__item .address {
	margin: 0 0 20px 0;
}

.table-accordion__item .head span {
	font-family: "glober_regular", "sans-serif";
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	display: inline-block;
}

.table-accordion__item .items {
	display: none;
}

.table-accordion__item .items .item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 10px;
}

.table-accordion__item .items .item:nth-child(even) {
	background-color: rgba(133, 177, 132, .1);
}

.table-accordion__item .items .item div {
	max-width: 50%;
	font-family: "glober_regular", "sans-serif";
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	display: inline-block;
}






@media (max-width: 992px) {
	.hidden-mobile {
		display: none;
	}
}
