/* Columns */
.col-xs-101,.col-xs-102,.col-xs-103,.col-xs-104,.col-xs-105,.col-xs-106,.col-xs-107,.col-xs-108,.col-xs-109,.col-xs-110,
.col-sm-101,.col-sm-102,.col-sm-103,.col-sm-104,.col-sm-105,.col-sm-106,.col-sm-107,.col-sm-108,.col-sm-109,.col-sm-110,
.col-md-101,.col-md-102,.col-md-103,.col-md-104,.col-md-105,.col-md-106,.col-md-107,.col-md-108,.col-md-109,.col-md-110,
.col-lg-101,.col-lg-102,.col-lg-103,.col-lg-104,.col-lg-105,.col-lg-106,.col-lg-107,.col-lg-108,.col-lg-109,.col-lg-110 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}

.col-xs-101 { width: 10%; float: left; }
@media (min-width: 768px) { .col-sm-101 { width: 10%; float: left;} }
@media (min-width: 992px) { .col-md-101 { width: 10%; float: left; } }
@media (min-width: 1200px) { .col-lg-101 { width: 10%; float: left; } }

.col-xs-102 { width: 20%; float: left; }
@media (min-width: 768px) { .col-sm-102 { width: 20%; float: left;} }
@media (min-width: 992px) { .col-md-102 { width: 20%; float: left; } }
@media (min-width: 1200px) { .col-lg-102 { width: 20%; float: left; } }

.col-xs-103 { width: 30%; float: left; }
@media (min-width: 768px) { .col-sm-103 { width: 30%; float: left;} }
@media (min-width: 992px) { .col-md-103 { width: 30%; float: left; } }
@media (min-width: 1200px) { .col-lg-103 { width: 30%; float: left; } }

.col-xs-104 { width: 40%; float: left; }
@media (min-width: 768px) { .col-sm-104 { width: 40%; float: left;} }
@media (min-width: 992px) { .col-md-104 { width: 40%; float: left; } }
@media (min-width: 1200px) { .col-lg-104 { width: 40%; float: left; } }

.col-xs-105 { width: 50%; float: left; }
@media (min-width: 768px) { .col-sm-105 { width: 50%; float: left;} }
@media (min-width: 992px) { .col-md-105 { width: 50%; float: left; } }
@media (min-width: 1200px) { .col-lg-105 { width: 50%; float: left; } }

.col-xs-106 { width: 60%; float: left; }
@media (min-width: 768px) { .col-sm-106 { width: 60%; float: left;} }
@media (min-width: 992px) { .col-md-106 { width: 60%; float: left; } }
@media (min-width: 1200px) { .col-lg-106 { width: 60%; float: left; } }

.col-xs-107 { width: 70%; float: left; }
@media (min-width: 768px) { .col-sm-107 { width: 70%; float: left; } }
@media (min-width: 992px) { .col-md-107 { width: 70%; float: left; } }
@media (min-width: 1200px) { .col-lg-107 { width: 70%; float: left; } }

.col-xs-108 { width: 80%; float: left; }
@media (min-width: 768px) { .col-sm-108 { width: 80%; float: left; } }
@media (min-width: 992px) { .col-md-108 { width: 80%; float: left; } }
@media (min-width: 1200px) { .col-lg-108 { width: 80%; float: left; } }

.col-xs-109 { width: 90%; float: left; }
@media (min-width: 768px) { .col-sm-109 { width: 90%; float: left; } }
@media (min-width: 992px) { .col-md-109 { width: 90%; float: left; } }
@media (min-width: 1200px) { .col-lg-109 { width: 90%; float: left; } }

.col-xs-110 { width: 100%; float: left; }
@media (min-width: 768px) { .col-sm-110 { width: 100%; float: left; } }
@media (min-width: 992px) { .col-md-110 { width: 100%; float: left; } }
@media (min-width: 1200px) { .col-lg-110 { width: 100%; float: left; } }
/* Columns */

/* Offset */
.col-xs-offset-110 {margin-left: 100%;}
.col-xs-offset-109 {margin-left: 90%;}
.col-xs-offset-108 {margin-left: 80%;}
.col-xs-offset-107 {margin-left: 70%;}
.col-xs-offset-106 {margin-left: 60%;}
.col-xs-offset-105 {margin-left: 50%;}
.col-xs-offset-104 {margin-left: 40%;}
.col-xs-offset-103 {margin-left: 30%;}
.col-xs-offset-102 {margin-left: 20%;}
.col-xs-offset-101 {margin-left: 10%;}
.col-xs-offset-100 {margin-left: 0%;}

@media (min-width: 768px) {
	.col-sm-offset-110 {margin-left: 100%;}
	.col-sm-offset-109 {margin-left: 90%;}
	.col-sm-offset-108 {margin-left: 80%;}
	.col-sm-offset-107 {margin-left: 70%;}
	.col-sm-offset-106 {margin-left: 60%;}
	.col-sm-offset-105 {margin-left: 50%;}
	.col-sm-offset-104 {margin-left: 40%;}
	.col-sm-offset-103 {margin-left: 30%;}
	.col-sm-offset-102 {margin-left: 20%;}
	.col-sm-offset-101 {margin-left: 10%;}
	.col-sm-offset-100 {margin-left: 0%;}
}

@media (min-width: 992px) {
	.col-md-offset-110 {margin-left: 100%;}
	.col-md-offset-109 {margin-left: 90%;}
	.col-md-offset-108 {margin-left: 80%;}
	.col-md-offset-107 {margin-left: 70%;}
	.col-md-offset-106 {margin-left: 60%;}
	.col-md-offset-105 {margin-left: 50%;}
	.col-md-offset-104 {margin-left: 40%;}
	.col-md-offset-103 {margin-left: 30%;}
	.col-md-offset-102 {margin-left: 20%;}
	.col-md-offset-101 {margin-left: 10%;}
	.col-md-offset-100 {margin-left: 0%;}
}

@media (min-width: 1200px) {
	.col-lg-offset-110 {margin-left: 100%;}
	.col-lg-offset-109 {margin-left: 90%;}
	.col-lg-offset-108 {margin-left: 80%;}
	.col-lg-offset-107 {margin-left: 70%;}
	.col-lg-offset-106 {margin-left: 60%;}
	.col-lg-offset-105 {margin-left: 50%;}
	.col-lg-offset-104 {margin-left: 40%;}
	.col-lg-offset-103 {margin-left: 30%;}
	.col-lg-offset-102 {margin-left: 20%;}
	.col-lg-offset-101 {margin-left: 10%;}
	.col-lg-offset-100 {margin-left: 0%;}
}
/* Offset */
