.featured-items{
	padding: 20px 0;
}
	@media (max-width: 992px){
		.featured-items{
			padding: 10px 0;
		}
	}

.featured-items__wrapper{
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
	@media (max-width: 992px){
		.featured-items__wrapper{
			align-items: flex-start;
			justify-content: center;
		}
	}

.featured-items__wrapper .item{
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item{
			flex-direction: column;
			justify-content: center;
		}
	}

.featured-items__wrapper .item:nth-child(2){
	border-left: 1px solid #437945;
	padding-left: 46px;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item:nth-child(2){
			padding-left: 0;
		}
	}

.featured-items__wrapper .item .icon{
	/* width: 120px; */
	display: flex;
	align-items: center;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item .icon{
			width: 80px;
			margin-bottom: 5px;
		}
	}

.featured-items__wrapper .item .icon img{
	width: 100%;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item .icon img{
			height: 80px;
			width: auto;
			margin-bottom: 5px;
		}
	}

.featured-items__wrapper .item .text{
	width: calc(100% - 120px);
	padding-left: 35px;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item .text{
			width: 100%;
			padding-left: 0;
			padding: 0 5px;
		}
	}

.featured-items__wrapper .item .text h2{
	margin-top: 0;
	/* font-family: "glober_regular", "sans-serif";
	font-weight: 600;
	font-size: 36px;
	line-height: 42px; */
	margin-bottom: 5px;
	color: #285626;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item .text h2{
			/* font-size: 18px;
			line-height: 21px; */
			font-size: 18px;
			text-align: center;
			margin-bottom: 3px;
		}
	}

.featured-items__wrapper .item .text p{
	font-family: "glober_regular", "sans-serif";
	/* font-size: 24px;
	line-height: 29px; */
	color: #333333;
	font-size: 24px;
	margin-top: 0;
}
	@media (max-width: 992px){
		.featured-items__wrapper .item .text p{
			/* font-size: 14px;
			line-height: 17px; */
			font-size: 14px;
			text-align: center;
		}
	}
