.visible {
  display: initial !important;
}

#map {
  width: 100%;
  height: 400px;
}

.map {
  width: 100%;
}

@media (min-width: 768px) {
  .map {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
  }
  .mw-100 {
    max-width: 100px;
  }
  .mw-200 {
    max-width: 200px;
  }
  .mw-300 {
    max-width: 300px;
  }
  .mw-400 {
    max-width: 400px;
  }
  .mw-500 {
    max-width: 500px;
  }
  .mw-600 {
    max-width: 600px;
  }
  .mw-700 {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .row--flex {
    display: flex;
  }
}

.adjustPadding {
  padding-bottom: 20px;
}

.twoColumnPadding {
  padding-bottom: 40px;
}

/* fix images floating out of the box */
#waste-results img {
  width: 100% !important;
}

.orangeColor * {
  color: #fc6921;
}

.illustrations__text p {
  margin-top: 0;
}

.wordWrap {
  word-wrap: break-word;
}

/*** Shortcode BUTTON ***/
div.form__button__link a {
  display: inline-block;
  margin-top: 1.25rem;
  padding: 0.625rem 5rem 0.625rem 1.25rem;
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  cursor: pointer;
  text-decoration: none;
}
div.form__button__link a:focus,
div.form__button__link a:hover {
  transition: all 0.2s ease;
}
div.form__button__link--primary a {
  color: #ffffff;
  background-color: #376f40;
  border: 1px solid #376f40;
  border-radius: 6px;
  background-image: url("../images/svg/arrow-white-right.svg");
}
div.form__button__link--primary a:focus,
div.form__button__link--primary a:hover,
div.form__button__link--primary a:active {
  background-image: url("../images/svg/arrow-green-right.svg");
  background-color: transparent;
}
div.form__button__link--primary a:focus,
div.form__button__link--primary a:hover {
  border-color: #376f40;
  color: #333;
}
div.form__button__link--primary a:active {
  border-color: #376f40;
  color: #376f40;
}

/*** Forms ***/
textarea.form__text.form__text--small {
  width: 100%;
  height: 150px;
}

textarea.form__text.form__text--small.form__text--rows {
	height: 100px;
}

.loading {
  display: inline-block;
  width: 43px;
  height: 11px;
  background: url("../images/ajax_loading.gif") center center no-repeat;
  vertical-align: bottom;
  margin-left: 20px;
}

a.form-block {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: default;
  z-index: 999;
}

.indent40 {
  margin-left: 40px;
}

.formAddress ul.navigationlist {
  margin-top: -1.25rem;
  margin-bottom: 1.25rem;
}

.formAddress .navigationlist__link:hover,
.formAddress .navigationlist__link:focus {
  color: #3a7b2b;
  background-color: #fff;
  transition: all 0.2s ease;
}

form.spaceless .form__legend {
  padding-top: 1.25rem;
  margin-top: 0;
}

.hideField .form__label:first-child,
.hideField .form__legend:first-child {
  margin-top: 1.25rem;
}

#alternativeSearch .form__label:first-child {
  margin-top: 0;
}

/*** Bootstrap Alerts ***/
/*.alert {
  margin: 1.25rem 0;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
  margin-top: 0;
}
.alert > ul {
  margin-left: 20px;
}
.alert > p + p {
  margin-top: 5px;
}*/
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
/*
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}*/
.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
/*
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}*/

/*** Custom Address Lookup ***/
span.inside-label {
  font-family: "glober_regular", sans-serif;
  text-transform: none;
}

.form__label--small {
  width: 50%;
  margin: 1.25rem 0;
}
.form__text--xs {
  min-width: 70px;
}
.form__label--clear {
  clear: both;
}
.select2-white-wrap {
  width: 95%;
}
@media (max-width: 767px) {
  .form__text--xs {
    width: 45%;
  }
  .select2-white-wrap {
    width: 100%;
  }
}
.select2-white-wrap .select2-container {
  display: block;
  border-radius: 0;
  border: 1px solid #376f40;
}
.select2-white-wrap .select2-container span {
  font-family: "glober_regular", sans-serif;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #333333;
}
.select2-white-wrap .select2-container:hover {
  border: 1px solid #376f40;
  transition: all 0.2s ease;
}

label .select2-selection__rendered {
  text-transform: none;
}

.boxColorWhite dl,
.boxColorWhite dt,
.boxColorWhite p,
.boxColorWhite a {
  color: #fff;
}

.lifecycle .imageResult {
  display: block;
  text-align: center;
}

.introduction--small > p:first-child {
  margin-top: 0px;
}

.recycling-infobox select {
  font-size: 1.625rem;
}

.recycling-infobox select > option {
  padding-top: 0;
  padding-bottom: 0;
}

.form__select::-ms-expand {
  display: none;
}

.form__select {
  background-color: #fff;
  border: 1px solid #376f40;
}

.form__text:read-only {
  border-color: #a0a3a4;
  color: #a0a3a4;
  background-color: transparent;
}

input[readonly] {
  border-color: #a0a3a4;
  color: #a0a3a4;
  background-color: transparent;
}

.form__text:-moz-read-only {
  border-color: #a0a3a4;
  color: #a0a3a4;
  background-color: transparent;
}

.form__period .form__text:read-only {
  border-color: #285626;
  color: #333;
  background-color: white;
}

.form__period input[readonly] {
  border-color: #285626;
  color: #333;
  background-color: white;
}

.form__period .form__text:-moz-read-only {
  border-color: #285626;
  color: #333;
  background-color: white;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 2px;
}

div#alternativeSearch > span:first-child {
  display: inline-block;
  margin-bottom: 2.4rem;
}

.basicSearchCustom .form__label {
  margin-bottom: 0;
}

.basicSearchCustom span.inside-label {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  font-family: "glober_regular";
  display: block;
}

.basicSearchCustom .js-suggestions .navigationlist {
  margin-top: 0;
}

figure figcaption,
figure figcaption p {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  font-family: "glober_regular";
  display: block;
}

.headerMobile {
  height: auto;
}

.employee__name h3 {
  color: #376f40;
}

.imagelist__illustration {
  padding-left: 1.2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

/*
.imagelist__image {
	padding-left: 0;
	border-radius: 100%;
	background-color: #F4F2F1;
}*/

.image__wrap {
  border-radius: 100%;
  background-color: #f4f2f1;
  width: 120px;
  height: 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.image__wrap .imagelist__image {
  padding-left: 0;
  max-width: 60px;
  max-height: 60px;
  margin-top: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .image__wrap {
    width: 140px;
    height: 140px;
  }
  .image__wrap .imagelist__image {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 567px) {
  .image__wrap {
    width: 70px;
    height: 70px;
  }
  .image__wrap .imagelist__image {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 360px) {
  .image__wrap {
    width: 50px;
    height: 50px;
  }
  .image__wrap .imagelist__image {
    width: 25px;
    height: 25px;
  }
}

.hide {
  display: none;
}

.ui-datepicker th,
.ui-datepicker td {
  text-align: center;
  width: calc(100% / 10);
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center;
}

.customBtnMinSide {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .customBtnMinSide {
    margin-top: 10px;
  }
}

.form__file {
  width: 50%;
  min-width: 220px;
}

hideField propertyContracts {
  padding-top: 2.4rem;
  margin-top: 1.25rem;
}

.form__button--primary:disabled {
  cursor: default !important;
  border-color: #285626;
  color: #333;
  background-image: url("../svg/arrow-green-right.svg");
  background-color: transparent;
}

div.hideField.propertyContracts > p {
  margin-top: 1rem;
  padding-top: 1rem;
}

/* RFD-450 */
.form__button--primary:disabled.submitFormExceptionOpacity {
  color: #fff;
  background-color: #285626;
  border: 1px solid #285626;
  background-image: url("../svg/arrow-white-right.svg");
  opacity: 0.5;
}

.form__label.form__label--radio.js-change.js-housing.form__label--sublabel {
  /* display: none; */
}

.form__label--radio.form__label--sublabel {
  margin-left: 35px;
}

.form__label--radio.form__label--sublabel .form__radio {
  width: 15px;
  min-width: 15px;
  height: 15px;
}
.form__label--radio.form__label--sublabel .form__radio:after {
  width: 7px;
  height: 7px;
  left: 2px;
  top: 2px;
}

@media (min-width: 768px) {
  .form__label--radio.form__label--sublabel .form__radio {
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
  .form__label--radio.form__label--sublabel .form__radio:after {
    width: 13px;
    height: 13px;
    left: 4px;
    top: 4px;
  }
}

.formExceptionHeadline {
  color: #285626;
}

h2.formException--color {
  color: #333;
}

label.form__label.form__label--checkbox.agreement-checkbox-form-exception {
  padding-top: 1.2rem;
}

@media (max-width: 1199px) {
  label.form__label.form__label--checkbox.agreement-checkbox-form-exception {
    padding-bottom: 1.2rem;
  }
}

h2.formException--fontweight {
  font-weight: normal;
}

@media (min-width: 768px) {
  article.content-article table.no-wrap-1 tr td:nth-child(1),
  article.content-article table.no-wrap-2 tr td:nth-child(2),
  article.content-article table.no-wrap-3 tr td:nth-child(3),
  article.content-article table.no-wrap-4 tr td:nth-child(4),
  article.content-article table.no-wrap-5 tr td:nth-child(5) {
    white-space: nowrap;
  }
}

.alternative-search-compost {
	margin-top: 1.2rem;
}
