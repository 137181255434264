.content-list .img {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.content-list .img img,
.content-list .img iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	height: auto;
	width: 100%;
	transform: translate(-50%, -50%);
}

.content-list .img.aspectRatio--330x231 {
	padding-top: calc((231 / 330) * 100%);
}

@media (max-width: 992px) {
	.content-list .img.aspectRatio--358x239 {
		padding-top: calc((239 / 358) * 100%);
	}

	.content-list .img img,
	.content-list .img iframe {
		/* width: 95%; */
		height: 100%;
	}
}

.content-list__title {
	margin-bottom: 30px;
}

@media (max-width: 992px) {
	.content-list__title {
		margin-bottom: 17px;
	}
}

.content-list__title h2 {
	/* font-size: 36px;
	line-height: 43px; */
	color: #285626;
	/* font-weight: 600;
	font-family: "glober_regular", "sans-serif"; */
	margin-top: 0;
}

@media (max-width: 992px) {
	.content-list__title h2 {
		/* font-size: 22px;
		line-height: 27px; */
	}
}

.content-list__items {
	display: flex;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 45px;
	margin-bottom: 68px;
}

.content-list__items .withSeeMoreLink {
	margin-bottom: 5px;
}

@media (max-width: 992px) {
	.content-list__items {
		row-gap: 35px;
		margin-bottom: 45px;
	}
}

.content-list__items>div {
	width: calc(33.33% - 20px);
}

@media (max-width: 992px) {
	.content-list__items>div {
		width: 100%;
	}
}

.content-list__items .item {
	text-decoration: none;
	display: block;
}

.content-list__items .item:hover .img {
	opacity: .9;
}

.content-list__items .img {
	margin-bottom: 22px;
}

.content-list__items .info h3 {
	/* font-size: 20px;
	line-height: 24px; */
	color: #285626;
	font-size: 20px;
	/* font-weight: 600;
	font-family: "glober_regular", "sans-serif"; */
	margin-top: 0;
	margin-bottom: 5px;
}

.content-list__items .info p {
	/* font-size: 15px;
	line-height: 19px; */
	color: #333333;
	font-size: 16px;
	/* font-family: "glober_regular", "sans-serif"; */
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.content-list__seeAll {
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 992px) {
	.content-list__seeAll {
		margin-bottom: 25px;
	}
}

.content-list__seeAll a {
	font-size: 20px;
	line-height: 23px;
	color: #333333;
	font-family: "glober_regular", "sans-serif";
}

.content-list .pubdate {
	font-size: 14px !important;
	margin: 20px 0 10px 0 !important;
}
