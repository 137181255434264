.container--idporten .row:first-child {
	margin-right: 0;
}

.header.container-fluid {
	overflow: visible;
}

#searchInput::-ms-clear {
	display: none;
}

/*.navbar__search {*/

/*display: block;*/

/*width: 162px;*/

/*position: relative;*/

/*border: 1px solid #B4B4B4;*/

/*border-radius: 100px;*/

/*}*/

.navbar__search--icon--enlarge {
	transform: translate(230px, -37px);
}

nav .navbar__search--icon {
	position: absolute;
	height: 30px;
	pointer-events: none;
	transform: translate(114px, -37px);
}

.navigationlist__autocomplete {
	position: absolute;
	z-index: 1;
	border: 2px solid #f0f0f0;
	border-top: 0;
	transform: translate(-139px, 10px);
	box-sizing: border-box;
	cursor: pointer;
}

.navigationlist__autocomplete__button {
	border: none;
	border-radius: 0;
	text-align: left;
	padding: 12px;
	border-bottom: 2px solid #f7f7f7;
}

.factbox__content .select2 {
	min-width: 425px;
}

@media(max-width: 576px) {
	.factbox__content .select2 {
		min-width: 100%;
	}
}

.navigationlist__autocomplete__item {
	cursor: pointer;
	display: inline-block;
	width: 400px;
	padding: 12px;
	height: 100%;
	text-decoration: none;
	background-color: buttonface;
	color: #333;
	font-size: 1rem;
	line-height: 1.6;
}

@media screen and (min-width: 768px) {
	.navigationlist__autocomplete__item {
		font-size: 1.2rem;
		line-height: 1.4;
	}
}

.navigationlist__autocomplete__item:hover {
	background-color: #f7f7f7;
}

.navigationlist__autocomplete__item--true {
	background-color: #f7f7f7;
}

@media (max-width: 1199px) and (min-width: 992px) {
	.menu {
		margin-top: 0.8rem;
	}

	.nav {
		z-index: 9997;
	}

	.rfd-nav {
		z-index: 9999;
	}

	.menu__item {
		width: 100%;
	}

	.menu__link--mypage {
		border: none;
	}

	.menu__link {
		padding-left: 0.8rem;
		width: 100%;
		border-bottom: 1px solid #3a7c37;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: rgb(58, 124, 55);
		border-radius: 0;
	}

	.menu__link.menu__link--direct {
		display: block !important;
	}
}

@media (max-width: 1200px) {
	.navbar {
		margin-right: 0px;
	}

	.navbar__search {
		width: 100%;
		margin-top: 10px;
	}

	.navbar__search--icon {
		/*transform: translate(-33px, -38px);*/
		right: 0;
	}

	.navigationlist__autocomplete {
		transform: translate(0px, 0px);
		width: calc(100% - 30px);
		margin-top: 0px;
	}

	.navigationlist__autocomplete__button {
		width: 100%;
	}
}

.container-divider {
	display: none;
}

@media (max-width: 400px) {
	.container-divider {
		display: inline-block;
		width: calc(100% - 20px);
		margin-right: 10px;
		margin-left: 10px;
		margin-top: 15px;
	}
}

@media (max-width: 768px) {

	figure figcaption,
	figure figcaption p {
		font-size: 100%;
	}
}

figure figcaption:empty {
	display: none;
}

.form--exception h2 {
	margin-top: 2.4rem;
}

.form--exception legend {
	padding-top: 2.4rem;
}

/*.container-fluid .row:first-child{
    margin-right: 0;
}*/

@media (min-width: 1200px) {
	.search--item {
		float: right;
		margin-right: 15px;
	}
}

.header {
	padding: 0.6rem 15px;
}

/*List branches on frontpage [RFD-321]*/

.factbox__content .row dl.open__time {
	margin-top: 0px;
}

.form__button {
	white-space: normal;
}

@media(max-width: 576px) {
	.form__button {
		text-align: left;
	}
}

.loading-circle {
	width: 290px;
	height: 290px;
	background-image: url('../images/svg/rfd-circle.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin-left: auto;
	margin-right: auto;
}

/* ----- invoices list page ----- */
table.invoices-table,
table.paid-invoices-table,
table.change-log-table {
	display: none;
}

.notification {
	font-size: 0.8rem;
	line-height: 1.6;
	padding: 0.4rem;
}

.notification--danger {
	color: #fff;
	background-color: #ce4703;
	border: 1px solid #ce4703;
}

.notification--warning {
	color: #333;
	background-color: #fff;
	border: 1px solid #ce4703;
}

p.no-invoices-warning,
p#noLogsWarning {
	display: none;
}

#noPaidNoUnpaid,
#invalidProperty {
	margin-top: 2.4rem;
}

/* ------------------------------ */

/* --------Factbox style---------- */
.factbox--gray {
	border-color: #e5e2df;
	margin-bottom: 1.2rem;
}

.factbox--solid {
	border-style: solid;
}

.factbox--dashed-v2 {
	border-width: 2px;
	border-style: dashed;
}

.factbox__title-empty {
	margin-bottom: -1.2rem;
}

/* --------Factbox style---------- */

/* [RFD-407] */
div#alternativeSearch>span:first-child {
	margin-bottom: 0;
	padding-top: 2.4rem;
}

/*[RFD-436]*/
#openinghours>h2 {
	margin-top: 3.6rem;
}

/*[RFD-531]*/
.garbage-container-sigdal {
	width: auto;
	height: 100%;
	max-height: 116px;
}

@media (min-width: 540px) {
	.garbage-container-sigdal {
		max-height: 174px;
	}
}

@media (min-width: 768px) {
	.garbage-container-sigdal {
		max-height: 232px;
	}
}

@media (min-width: 992px) {
	.garbage-container-sigdal {
		max-height: 232px;
	}
}

@media (min-width: 1200px) {
	.garbage-container-sigdal {
		max-height: 348px;
	}
}

/* -------- RFD-595 ---------- */
@media (min-width: 1200px) {
	.container-fluid {
		overflow: hidden;
	}

	.row {
		margin-left: -30px !important;
		margin-right: -30px !important;
	}
}

#main-content {
	overflow: hidden;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.frontpage-bg-gradient::before {
	z-index: -1;
}

/* Header */
.header {
	border-top: none;
	height: 123px;
	padding: 0 !important;

}

@media (max-width: 1200px) {
	.header {
		height: auto;
		min-height: 80px;
	}
}

.header__logo {
	width: 65px;
}

@media (max-width: 1200px) {
	.header__logo {
		width: 50px;
	}
}

@media (max-width: 1200px) {
	.header header {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.header .rfd-nav {
		height: 80px;
	}
}

.header>.row>div,
.header .row,
.header .container,
.header .vertical-align-lg {
	height: 100%;
}

.search__input {
	border: 1px solid #707070;
}

@media (min-width: 1200px) {
	.search__input {
		width: 100px;
		height: 40px;
	}
}

.search__input--icon {
	background-image: url("../svg/search-dark.svg");
}

/* Header */

/* Footer */
.footer {
	background-image: none;
	background-color: rgba(129, 179, 128, .10);
	padding-top: 50px;
	padding-bottom: 60px;
}

@media (min-width: 1200px) {
	.footer {
		padding-top: 40px;
		padding-bottom: 45px;
	}
}

.footer__header {
	margin-bottom: 16px;
}

@media (max-width: 992px) {
	.footer__header {
		margin-bottom: 0;
	}
}

.footer__header .intro h4 {
	/* font-family: "glober_semibold", "sans-serif";
	font-size: 24px;
	line-height: 29px; */
	margin-top: 0;
}

/* @media (max-width: 992px){
	.footer__header .intro h2{
		font-size: 22px;
		line-height: 27px;
	}
} */

.footer__header .intro p {
	margin-bottom: 0;
	margin-top: 4px;
	/* font-size: 16px;
	line-height: 19px; */
}

@media (max-width: 992px) {
	.footer__header .intro p {
		margin-top: 20px;
	}
}

.footer__header .intro p a {
	color: #285626;
	/* font-weight: 600; */
	/* font-family: "glober_semibold", "sans-serif"; */
}

@media (max-width: 992px) {
	.footer__header .intro p a {
		color: #C3511C;
		/* font-weight: 600; */
	}
}

@media (max-width: 992px) {
	.footer__header .btn-wrapper {
		display: none;
	}
}

.footer__header .btn-wrapper .form__button {
	margin-top: 0;
	width: 210px;
	text-align: center;
}

/* .footer h4{
	font-family: "glober_semibold", "sans-serif";
	font-size: 20px;
	line-height: 24px;
	margin-top: 24px;
}
	@media (max-width: 992px){
		.footer h4{
			font-size: 18px;
			line-height: 21px;
			margin-top: 19px;
		}
	} */

/* .footer p{
	font-family: "glober_regular", "sans-serif";
	font-size: 16px;
	line-height: 24px;
	margin-top: 8px;
	margin-bottom: 0;
}
	@media (max-width: 992px){
		.footer p{
			font-size: 16px;
			line-height: 21px;
		}
	} */

.footer__logo {
	margin-top: 72px;

	img {
		height: 65px;
		width: 65px;
	}
}

@media (max-width: 992px) {
	.footer__logo {
		margin-top: 56px;
	}
}

.footer p+p {
	margin-top: 0;
}

.social.social--facebook {
	display: flex;
	align-items: center;
}

.social--facebook::before {
	width: 32px;
	height: 32px;
}

.social--facebook::before {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzIgMzIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6IzI4NTYyNjt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJQYXRoIiBjbGFzcz0ic3QwIiBkPSJNMTYsMEM3LjIsMCwwLDcuMiwwLDE2czcuMiwxNiwxNiwxNnMxNi03LjIsMTYtMTZjMCwwLDAsMCwwLDBDMzIsNy4yLDI0LjgsMCwxNiwweiBNMTkuOCwxMS4xaC0yLjQKCWMtMC4zLDAtMC42LDAuNC0wLjYsMC45djEuN2gzbC0wLjUsMi41aC0yLjZ2Ny40aC0yLjh2LTcuNGgtMi42di0yLjVoMi42di0xLjVjLTAuMS0yLDEuNC0zLjcsMy4zLTMuOGMwLDAsMC4xLDAsMC4xLDBoMi40VjExLjF6IgoJLz4KPC9zdmc+Cg==");
}

@media (max-width: 992px) {

	.footer .col-md-103.col-md-offset-101,
	.footer .col-md-103 {
		/* margin-top: 54px; */
	}

	.footer .footer__header .col-md-103 {
		margin-top: 0;
	}
}

/* Footer */

/* -------- RFD-592 ---------- */

/* -------- RFD-637 - BEGIN ---------- */
.content-article .pubdate {
	font-size: 14px !important;
	margin: 20px 0 -10px 0 !important;
}

.calendar-undertext-buttonless {
	margin-bottom: 30px;
}

.calendar-link-button {
	margin-bottom: 30px;
}

/* -------- RFD-637 - END ---------- */

/* -------- RFD-640 - Begin ---------- */

form .checkbox-form-gardenwaste {
	width: 1px;
	height: 1px;
	margin: 0;
	padding: 0;
	margin-right: -10px;
	border-color: transparent;
	color: transparent;
	background-color: transparent;
	outline: 0px solid transparent;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
}

/* -------- RFD-640 - End ---------- */
