.block-text{

}

.block-text h2{
	margin: 0;
	margin-bottom: 0;
	font-size: 26px;
	/* font-size: 26px;
	line-height: 32px; */
}

@media (max-width: 992px){
	.block-text h1{
		font-size: 22px;
		/* font-size: 22px;
		line-height: 27px; */
	}
	.block-text h2{
		font-size: 22px;
	}

	.block-text h3{
		font-size: 20px;
	}
}

.block-text  .block-text__intro p{
	font-size: 18px;
	/* line-height: 21px; */
}
	@media (max-width: 992px){
		.block-text  .block-text__intro p{
			/* font-size: 16px;
			line-height: 19px; */
		}
	}

.block-text .block-text__image .image{
	margin-top: 0;
}
@media (max-width: 992px){
	.block-text .block-text__image .image{
		margin-bottom: 17px;
	}
}

.block-text .content{
	align-items: center;
    display: flex;
}
	@media (max-width: 992px){
		.block-text .content{
			flex-direction: column-reverse;
		}
	}

.block-text.left-image .content{
    flex-direction: row-reverse;
}
	@media (max-width: 992px){
		.block-text.left-image .content{
			flex-direction: column-reverse;
		}
	}

.block-text.left-image .content .block-text__intro{
	margin-left: 0%;
}

.block-text.left-image .content .block-text__image{
	margin-left: 10%;
}
	@media (max-width: 992px){
		.block-text.left-image .content .block-text__image{
			margin-left: 0;
		}
	}
