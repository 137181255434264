#sortguide .row {
	margin-left: -15px !important;
	margin-right: -15px !important;
}

#sortguide .form__button--oneline {
	margin-top: 2.35rem;
}

@media screen and (min-width: 768px) {
	#sortguide .form__button--oneline {
		margin-top: 2.3rem;
	}
}

@media screen and (min-width: 992px) {
	#sortguide .form__button--oneline {
		margin-top: 2.8rem;
	}
}

#sortguide .form__label input[type='text'] {
	height: 46px;
}

#sortguide .form__label {
	margin-bottom: 10px;
}

#sortguide .form__text--large {
	min-width: initial;
}

.sorting-guide {
	overflow: inherit;
}
