.container--lightergreen {
	background: rgba(123, 179, 128, .1) !important;
	margin-bottom: 60px;
}

.waste-fetch-days {}

.pickup-title {
	margin-top: 2.4rem !important;
}

@media (max-width: 992px) {
	.pickup-title {
		margin-top: 1.2rem !important
	}
}

.waste-fetch-days h2 {
	margin-top: 0;
	font-size: 20px;
	/* font-size: 36px;
	line-height: 43px; */
	margin-bottom: 24px;
	color: #285626;
}

@media (max-width: 992px) {
	.waste-fetch-days h2 {
		/* font-size: 22px;
		line-height: 27px; */
		margin-bottom: 16px;
	}
}

.waste-fetch-days .wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.waste-fetch-days__content {
	width: calc(100% - 370px);
	padding-right: 20px;
	padding: 60px 0 55px 0;
}

@media (max-width: 992px) {
	.waste-fetch-days__content {
		width: 100%;
		padding: 15px 0 12px 0;
	}
}

.waste-fetch-days__image {
	width: 370px;
	padding: 15px 0 5px 0;
}

@media (max-width: 992px) {
	.waste-fetch-days #pickup .form__errormessage {
		max-width: 100% !important;
	}
}

.waste-fetch-days #pickup .navigationlist {
	max-width: 600px;
}

@media (max-width: 1200px) {
	.waste-fetch-days #pickup .navigationlist {
		max-width: 390px;
	}
}

@media (max-width: 992px) {
	.waste-fetch-days #pickup .navigationlist {
		max-width: 100%;
	}
}

.waste-fetch-days #pickup .navigationlist+.form__button {
	margin: 20px 0 5px 0 !important;
}

.waste-fetch-days #pickup .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.waste-fetch-days #pickup .row .col-sm-8 {
	width: calc(100% - 180px) !important;
	padding: 0;
}

@media (max-width: 992px) {
	.waste-fetch-days #pickup .row .col-sm-8 {
		width: calc(100% - 90px) !important;
		float: left;
	}
}

.waste-fetch-days #pickup .row .col-sm-4 {
	width: auto !important;
	padding-left: 10px;
	padding-right: 0;
}

@media (max-width: 992px) {
	.waste-fetch-days #pickup .row .col-sm-4 {
		width: 90px !important;
		float: left;
	}
}

.waste-fetch-days #pickup p {
	color: #333333;
	/* font-size: 20px;
	line-height: 24px; */
	margin-top: 20px;
	margin-bottom: 0;
}

@media (max-width: 992px) {
	.waste-fetch-days #pickup p {
		color: #333333;
		/* font-size: 16px;
		line-height: 19px; */
		margin-top: 12px;
		max-width: 270px;
	}
}

.waste-fetch-days #pickup p a {
	color: #C3511C;
}

.waste-fetch-days #pickup .col-sm-8 label {
	margin-top: 0;
	height: 40px;
}

.waste-fetch-days #pickup .col-sm-8 label input {
	height: 100%;
	border: none;
}

.waste-fetch-days #pickup .col-sm-4 input[type="submit"] {
	margin-top: 7px;
	height: 40px;
	width: 98px;
	line-height: 100%;
}

@media (max-width: 992px) {
	.waste-fetch-days #pickup .col-sm-4 input[type="submit"] {
		width: 100%;
	}
}

.waste-fetch-days__image img {
	margin-top: 0;
}
