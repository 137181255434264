.shortcuts {
	margin-bottom: 10px;
	margin-top: 0
}

.shortcuts__title {
	margin-bottom: 30px;
}

@media (max-width: 992px) {
	.shortcuts__title {
		margin-bottom: 10px;
	}
}

.shortcuts__title h2 {
	/* font-family: "glober_semibold", "sans-serif";
	font-size: 36px;
	line-height: 43px; */
	margin-top: 0;
}

@media (max-width: 992px) {
	.shortcuts__title h2 {
		/* font-size: 22px;
		line-height: 27px; */
	}
}


.shortcuts__important {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	margin-bottom: 35px;
}

@media (max-width: 992px) {
	.shortcuts__important {
		flex-direction: column;
		row-gap: 25px;
		margin-bottom: 40px;
	}
}

.shortcuts__important .item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: calc(50% - 15px);
	background: rgba(123, 179, 128, .1);
	border-radius: 16px;
	text-decoration: none;
}

.shortcuts__important .item:hover {
	background-color: rgba(254, 197, 83, 0.1);
}

@media (max-width: 992px) {
	.shortcuts__important .item {
		width: 100%;
		border-radius: 16px;
	}
}

.shortcuts__important .item .icon {
	width: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
}

@media (max-width: 992px) {
	.shortcuts__important .item .icon {
		width: 92px;
		padding: 10px;
	}
}

.shortcuts__important .item .icon img {
	height: auto;
	width: auto;
	max-width: 100%;
	max-height: 85px;
}

.shortcuts__important .item .text {
	width: calc(100% - 160px);
	padding: 35px 46px 35px 0;
}

@media (max-width: 992px) {
	.shortcuts__important .item .text {
		width: calc(100% - 92px);
		padding: 18px 22px 18px 0;
	}
}

.shortcuts__important .item .text h3 {
	margin-top: 0;
	/* font-family: "glober_semibold", "sans-serif";
	font-size: 26px;
	line-height: 31px; */
	font-size: 26px;
	margin-bottom: 5px;
	color: #285626;
}

@media (max-width: 992px) {
	.shortcuts__important .item .text h3 {
		font-size: 16px;
		line-height: 21px;
	}
}

.shortcuts__important .item .text p {
	/* font-family: "glober_regular", "sans-serif";
	font-size: 18px;
	line-height: 21px; */
	font-size: 18px;
	color: #333333;
	margin-top: 5px;
}

@media (max-width: 992px) {
	.shortcuts__important .item .text p {
		font-size: 16px;
		line-height: 19px;
	}
}









.shortcuts__normal {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
}

@media (max-width: 992px) {
	.shortcuts__normal {
		flex-direction: column;
		row-gap: 30px;
	}
}

.shortcuts__normal>div {
	width: calc(33.33% - 20px);
}

@media (max-width: 992px) {
	.shortcuts__normal>div {
		width: 100%;
	}
}

.shortcuts__normal .item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background: rgba(123, 179, 128, .1);
	border-radius: 16px;
	text-decoration: none;
	padding: 28px 20px 40px 20px;
	height: 100%;
}

@media (min-width: 993px) {
	.shortcuts__normal .item:hover {
		background-color: rgba(254, 197, 83, 0.1);
	}
}

@media (max-width: 992px) {
	.shortcuts__normal .item {
		flex-direction: row;
		border-radius: 16px;
		background: none;
		padding: 0;
	}
}

.shortcuts__normal .item .icon {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}

@media (max-width: 992px) {
	.shortcuts__normal .item .icon {
		width: 92px;
		margin-bottom: 0;
		padding: 10px;
	}
}

.shortcuts__normal .item .icon img {
	height: 84px;
	width: auto;
	max-width: 100%;
	max-height: 100%;
}

@media (max-width: 992px) {
	.shortcuts__normal .item .icon img {
		max-height: 60px;
	}
}

.shortcuts__normal .item .text {
	text-align: center;
}

@media (max-width: 992px) {
	.shortcuts__normal .item .text {
		width: calc(100% - 92px);
		text-align: left;
	}
}

.shortcuts__normal .item .text h4 {
	margin-top: 0;
	/* font-family: "glober_semibold", "sans-serif";
	font-size: 18px;
	line-height: 21px; */
	font-size: 18px;
	margin-bottom: 5px;
	color: #285626;
	max-width: 250px;
}

@media (max-width: 992px) {
	.shortcuts__normal .item .text h4 {
		max-width: 100%;
	}
}

.shortcuts__normal .item .text p {
	/* font-family: "glober_regular", "sans-serif";
	font-size: 16px;
	line-height: 19px; */
	font-size: 16px;
	color: #333333;
	margin-top: 5px;
	max-width: 250px;
}

@media (max-width: 992px) {
	.shortcuts__normal .item .text p {
		max-width: 100%;
	}
}
