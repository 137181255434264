.col-md-109.col-md-offset-101 .factbox,
.one-column .factbox {
	padding: 65px 70px;
}

@media (max-width: 992px) {

	.col-md-109.col-md-offset-101 .factbox,
	.one-column .factbox {
		padding: 15px 12px 30px 12px;
	}
}

.col-md-109.col-md-offset-101 .factbox__title,
.one-column .factbox__title {
	color: #333333;
	/* font-size: 26px;
	line-height: 31px;
	font-family: "glober_semibold", "sans-serif";
	font-weight: 600; */
}

@media (max-width: 992px) {

	.col-md-109.col-md-offset-101 .factbox__title,
	.one-column .factbox__title {
		/* font-size: 22px;
		line-height: 27px; */
	}
}

@media (max-width: 768px) {
	.factbox__content .select2.select2-container{
		width: 100%!important;
	}
}

.col-md-109.col-md-offset-101 .factbox__content p,
.one-column .factbox__content p,
.col-md-109.col-md-offset-101 .factbox .introduction p {
	color: #333333;
	/* font-size: 18px;
	line-height: 22px;
	font-family: "glober_regular", "sans-serif"; */
}

@media (max-width: 992px) {

	.col-md-109.col-md-offset-101 .factbox__content p,
	.one-column .factbox__content p,
	.col-md-109.col-md-offset-101 .factbox .introduction p,
	.one-column .factbox .introduction p {
		/* font-size: 16px;
		line-height: 19px; */
	}
}

.col-md-109.col-md-offset-101 .factbox__content p:first-child,
.one-column .factbox__content p:first-child,
.col-md-109.col-md-offset-101 .factbox .introduction p:first-child,
.one-column .factbox .introduction p:first-child,
.col-md-109.col-md-offset-101 .factbox .introduction,
.one-column .factbox .introduction {
	margin-top: 20px;
}

@media (max-width: 992px) {

	.col-md-109.col-md-offset-101 .factbox__content p:first-child,
	.one-column .factbox__content p:first-child,
	.col-md-109.col-md-offset-101 .factbox .introduction p:first-child,
	.one-column .factbox .introduction p:first-child,
	.col-md-109.col-md-offset-101 .factbox .introduction,
	.one-column .factbox .introduction {
		margin-top: 7px;
	}
}


.one-column .content {
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.one-column .content {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.one-column .content {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.one-column .content {
		width: 1170px;
	}
}

.one-column .content-row {
	margin-left: 0;
	padding: 0 0;
}

@media (max-width: 1200px) {
	.one-column .content-row {
		padding: 0 15px;
	}
}

@media (min-width: 992px) {
	.one-column .content-row {
		margin-left: 10%;
	}
}
