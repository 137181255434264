.hero{
	max-height: 440px;
	padding: 100px 0;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
	@media (max-width: 992px){
		.hero{
			min-height: 173px;
			padding: 36px 0;
		}
	}

.hero.hero--fade:after{
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: url('../images/fade-image.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	z-index: 1;
}

@media (max-width: 768px){
	.hero .container{
		width: 100%;
	}
}

.hero .content{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.hero .content h1{
	/* font-family: "glober_regular", "sans-serif";
	font-weight: 600;
	font-size: 32px;
	line-height: 40px; */
	text-align: right;
	text-shadow: 0px 0px 20px #000000;
	color: #ffffff;
	max-width: 700px;
	margin: 0;
	position: relative;
	z-index: 2;
}
	@media (max-width: 992px){
		.hero .content h1{
			/* font-family: "glober_semibold", "sans-serif";
			line-height: 18px;
			*/
			font-size: 14px;
			max-width: 225px;
		}
	}

.hero.hero--left .content{
	justify-content: flex-start;
}

.hero.hero--left .content h1{
	text-align: left;
}

.hero.hero--fade.hero--left:after{
	transform: rotate(180deg);
}
