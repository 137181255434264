.form-box {
	border: 3px solid #e5e2df;
	padding: 15px;
	margin-bottom: 32px;
}

.form-box h2 {
	/* font-family: "glober_regular", "sans-serif";
	font-size: 18px;
	line-height: 21px; */
	margin-top: 0;
	color: #333333;
}

.form-box p {
	margin-bottom: 20px;
	/* font-family: "glober_regular", "sans-serif";
	font-size: 18px;
	line-height: 1.4; */
	color: #333333;
}
	@media (max-width: 992px) {
		.form-box p {
			/* font-size: 14px; */
		}
	}

.form-box .form__button {
	margin-top: 0;
}
	@media (max-width: 992px) {
		.form-box .form__button {
			margin-top: 0;
			padding: 0 10px;
			width: 105px;
			height: 32px;
			/* font-size: 16px;
			line-height: 16px; */
			align-items: center;
			display: flex;
		}
	}

/* General form */
.form__legend {
	font-family: "glober_regular", "sans-serif";
	font-weight: 600;
	color: #285626;
}

.inside-label {
	margin-top: 7px;
	display: block;
}

.form__label input {
	margin-top: 7px;
}

.form--emphasis {
	box-shadow: none;
}
	@media (max-width: 768px) {
		.form--emphasis {
			padding: 0;
		}
	}

.form__label {
	margin-top: 12px;
}

.form__skip-button {
	padding-left: 30px;
	float: left;
}

@media (max-width: 992px) {
	.form__legend {
		/* font-size: 18px;
		line-height: 21px; */
	}

	.inside-label {
		margin-top: 16px;
		display: block;
		/* font-size: 16px;
		line-height: 19px; */
	}

	input.form__text.form__text--small {
		width: 100%;
		max-width: 100%;
	}

	.form__label {
		/* font-size: 16px;
		line-height: 19px; */
	}

	.form__label input[type='text'],
	.form__label input[type='email'],
	.form__label input[type='tel'] {
		padding: 5px 0.8rem;
	}

}
